<template>
  <div class="relative flex-1 overflow-y-scroll w-full">
    <detail-header-edit :menu="$store.getters['tradein/menu']"></detail-header-edit>
    <div class="flex flex-row w-full">
      <side-detail-menu></side-detail-menu>
      <customer v-if="$store.getters['tradein/menu'] === 'customer' && !$store.getters['seller/customerActive']"></customer>
      <customer-start v-if="$store.getters['tradein/menu'] === 'customer' && $store.getters['seller/customerActive']"></customer-start>
      <vehicle v-if="$store.getters['tradein/menu'] === 'vehicle'"></vehicle>
      <features v-if="$store.getters['tradein/menu'] === 'features'"></features>
      <checks v-if="$store.getters['tradein/menu'] === 'checks'"></checks>
      <pictures v-if="$store.getters['tradein/menu'] === 'pictures'"></pictures>
      <damages v-if="$store.getters['tradein/menu'] === 'damages'"></damages>
      <v-summary v-if="$store.getters['tradein/menu'] === 'summary'"></v-summary>
    </div>
  </div>
</template>

<script>
import Header from './Header';
import Side from './Side';
import Customer from './Customer';
import CustomerStart from '../search/Customer';
import Vehicle from './Vehicle';
import Checks from './Checks';
import Features from './Features';
import Pictures from './Pictures';
import Damages from './Damages';
import Summary from './Summary';


export default {
  name: "TradeIn",
  components: {
    'detail-header-edit': Header,
    'side-detail-menu': Side,
    'customer': Customer,
    'customer-start': CustomerStart,
    'vehicle': Vehicle,
    'checks': Checks,
    'features': Features,
    'pictures': Pictures,
    'damages': Damages,
    'v-summary': Summary
  },
}
</script>

<style scoped>

</style>